.resume-main-label {
    font-size: 15px;
    color: var(--ion-color-primary);
    font-weight: 500;
    text-transform: capitalize;
}

.resume-sub-label {
    font-size: 15px;
    color: var(--ion-color-dark);
    font-weight: 400;
    text-transform: capitalize;
}

.amount-col {
    text-align: right;
    display: flex;
}

.resume-amount-label{
    font-size: 15px;
    color: var(--ion-color-dark);
    font-weight: 400;
    text-transform: capitalize;
}