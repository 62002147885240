.pie-chart {
  width: 30%;
  margin: auto;
}
.line-chart {
  height: auto;
  width: 50%;
  margin: auto;
  background-color: white;
}

.line-styles {
  margin: 0;
}
.horizontal-chart {
  width: 40%;
  margin: auto;
}

.imc-bar > * {
  width: 100% !important;
  height: 80px !important;
}
