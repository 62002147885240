.lessons-title {
    font-size: 2.5rem;
    margin: auto;
}

.separator-style {
    background-color: var(--ion-color-light-tint);
    height: 0px;
    border: 1.04323px solid rgba(230, 230, 232, 0.7);
    margin-bottom: 1vh;
    width: 100%;
}

.class-activity-name {
    text-align: center;
    text-transform: capitalize;
}

.class-details {
    padding: 3px;
    text-align: center;
    text-transform: capitalize;
}

.icon-style {
    font-size: 64px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.card-styles {
    margin-bottom: 1vh;
    border-radius: 8px;
}

.card-text-style {
    text-align: center;
}

.ion-toast-text-style {
    text-align: center;
}

.modal-activity-name {
    text-align: center;
    text-transform: capitalize;
}

.modal-activity-description {
    text-align: justify;
    padding-top: 1rem;
}
