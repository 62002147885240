.darkInput {
  --background: #4D4D4D;
  --background-color: #4D4D4D;
  background-color: #4D4D4D;
  background: #4D4D4D;
  border: 1px solid #4D4D4D;
  border-radius: 14px;
  margin-bottom: 25px;
}

.innerDarkInput {
  --placeholder-color: white;
  --color:white;
  --placeholder-opacity: .8;
}

.defaultInput {
  border: 1px solid #4D4D4D;
  border-radius: 14px;
  margin-bottom: 25px;
}