.header-title {
    font-size: 2.5rem;
    margin: auto;
}

.separator-style {
    background-color: var(--ion-color-light-tint);
    height: 0px;
    border: 1.04323px solid rgba(230, 230, 232, 0.7);
    margin-bottom: 1vh;
    width: 100%;
}

.activity-name {
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    text-align: start;
    line-height: 30px;
}

.activity-description {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    text-align: start;
    color: var(--ion-color-medium-tint);
}

.activity-duration {
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    text-align: start;
    color: var(--ion-color-medium-tint);
}

.activity-status-title {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    text-align: end;
    color: var(--ion-color-medium-tint);
}

.activity-status {
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    text-align: end;
    color: var(--ion-color-medium-tint);
}

.icon-style {
    text-align: center;
}

.tutorial-name-video {
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    text-align: center;
    color: var(--ion-color-primary);
}

.cardio-name-device {
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    text-align: center;
    color: var(--ion-color-primary);
    margin-bottom: 1vh;
}

.datepicker-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.days-container-wrapper {
    width: 100%;
    overflow-x: auto;
}

.days-container-wrapper::-webkit-scrollbar {
    display: none;
}

.days-container {
    display: flex;
}

.day-card {
    background-color: var(--ion-color-light-tint);
    transition: background-color 0.3s ease, color 0.3s ease;
    border-radius: 8px;
    padding: 3%;
    margin: 1.9%;
    cursor: pointer;
    color: black;

    text-align: center;
    font-size: 14px;
}

.day-card.selected {
    color: white;
    background-color: var(--ion-color-primary);
}

.completed-day {
  color: white;
  background-color: var(--ion-color-dark);
}

.no-routine {
    color: lightgray;
    background-color: var(--ion-color-light-tint);
}

.club-subtitle-complete-day {
  font-size: 1rem;
  margin: auto;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
