.textarea-contract {
    border: 1px solid #F1F1F1;
    border-radius: 15px;
}

.nextbutton-contract{
    display: flex;
    width: 40vw;
    margin-left: auto;
    margin-right: auto;
}