.plans-title {
    font-size: 2rem;
    margin: auto;
}

.card-style {
    box-shadow: #4a4a4a;
    position: relative;
}

.card-header-basic {
    font-size: 1rem;
    color: #EDF5E4;
    text-align: center;
    background-image: linear-gradient(to right, #82BC41, #E3E321);
}

.card-header-premium {
    font-size: 1rem;
    color: #EDF5E4;
    text-align: center;
    background-image: linear-gradient(to right, #2B2C2E, #747272);
}

.card-subheader {
    color: #2B2C2E;
    border-bottom: 1px solid #C7C7C7;
}

.card-table-text {
    font-size: 0.7rem;
    text-align: center;
    color: #2B2C2E;
}

.card-buttons-style {
    font-size: 1rem;
}

.card-benefits {
    word-wrap: break-word;
    white-space: normal;
}

.error-icon-style {
    margin-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 80px;
}

.error-text-style {
    text-align: center;
    font-size: 1rem;
}

.grid-plans {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: flex-start;
}

.label-plans {
    font-size: .8rem;
    padding-left: 8px;
    width: 100%;
}

.icon-label-plans {
    max-width: 2rem;
}