.user-name{
  color: var(--ion-color-dark-tint);
}
.memberID {
  font-size: 1.2rem !important;
}

.profile-rounded {
  border-radius: 50%;
  display: block;
  height: 100px;
  margin: auto;
  width: 100px;
  object-fit: cover;
}

.profile-modules ion-item {
  color: var(--ion-color-dark-tint);
}

ion-icon {
  color: var(--ion-color-dark-tint);
  font-size: 1rem;
  display: flex;
  margin: auto;
  margin-right: 0;
}

.user-name ion-label{
  padding-bottom: 1rem;
}

.hrMargin{
  background-color: var(--ion-color-light-shade);
  height: .1rem !important;
  margin-top: 2rem;
  width: 40%;
}

.hrNoMargin{
  background-color: var(--ion-color-light-shade);
  height: .1rem !important;
  width: 40%;
}