.upper-corners-rounded {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  width: 100%;
  object-fit: cover;
}

.image-modal-swiper {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slide-box {
  width: 100%;
  text-align: center;
  background-color: #fff;
}

.img-container-slider {
  height: 15rem;
}

.small-text {
  font-size: 0.8rem;
  text-transform: capitalize;
}

.left-icon {
  margin: 0;
  margin-right: 0.5rem;
}

.wrapper-list {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.wrapper-icon-whatsapp {
  display: flex;
  align-items: center;
}

.label-Whatsapp {
  color: var(--ion-color-primary);
  font-size: .77rem;
  padding-left: 4px;
}

.button-text-back {
  margin-left: -5px !important;
  font-size: 1.1rem;
}

.col-items {
  text-align: center;
  align-items: center;
  margin-left: 3vw;
  margin-right: 3vw;
}

.grid-buttons-pool {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}