.container-image-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapper-image-modal {
    width: 100%;
    height: 100%;
}

.image-modal {
    object-fit: contain;
    height: 100%;
    width: 100%;
}

.close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    z-index: 3;
}