.row-list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.time-slot { 
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.col-list-info span {
    display: flex;
    padding-bottom: .5em;
    font-size: .73em;
    word-wrap: break-word;
}

.col-list-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.btn-reserve {
    height: 3.2em;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-reserve span{
    font-size: .7em;
    text-align: center;
}