.modal-access {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-modal-access {
    width: 100%;
    margin: 0 10%;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    padding: 1.2em;
}

.wrapper__content {
    padding-bottom: 1.6em;
}

.wrapper_actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
}

.content-modal-access {
    color: #ccc;
}


.btn-modal-access {
    color: var(--ion-color-primary);
    background: #fff;
    margin-left: 10px;
    font-size: .9em;
    padding: 7px 14px;
}