.grid-personaldata {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.avatar-personaldata {
    margin-top: 5vh;
    margin-bottom: 4vh;
    height: auto;
    width: 120px;
    max-width: 120px;
}

.label-personaldata {
    margin-left: 2vw;
}

.input-personaldata {
    border: 1px solid #F1F1F1;
    border-radius: 15px;
    margin-top: 1vh;
}

.nextbutton-personaldata {
    display: flex;
    width: 40vw;
    margin-left: auto;
    margin-right: auto;
}