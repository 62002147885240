.SignUp{
  font-size: small;
  font-weight: lighter;
  font-style: italic;
}

.alert-text-style {
  text-align: start;
}

.avisoRegistro {
  margin-top: -2vh;
  margin-bottom: -1vh;
  font-size: 13px;
}