
.my-plan-details {
  color: var(--ion-color-dark-tint);
}

ion-icon {
  color: var(--ion-color-dark-tint);
}

.profile-plan-elements{
  height: 5rem;
}

.profile-plan-detail{
  margin:auto;
  display: flex;
  align-items: center;
  width: 100%;
}
.profile-plan-icon{
  margin: auto;
}
.width-container{
  width: 100%;
  display: flex;
}
.col-container{
  width: 100%;
}

