.darkInput {
  --background: #4D4D4D;
  --background-color: #4D4D4D;
  background-color: #4D4D4D;
  background: #4D4D4D;
  border: 1px solid #4D4D4D;
  border-radius: 14px;
  margin-bottom: 25px;
}

.innerDarkInput {
  --placeholder-color: white;
  --color:white;
  --placeholder-opacity: .8;
}

.forgetPassword {
  float: right;
  color: white;
  font-size: small;
  font-style: italic;
}

.submitButton{
  margin-top: 1rem;
}

.changePasswordIcon{
  margin-right: 0.5rem;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}