.club-info {
  box-shadow: 1 !important;
}

ion-grid {
  padding: 0%;
}

.services-icons {
  font-size: 5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.services-icons-text {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 0;
}

.swiper-pagination-bullet-active {
  background: var(--ion-color-primary) !important;
}